import buttonStyles from '@styles/scss/buttons/_buttons.module.scss';
import styles from '@styles/scss/common/_bannerCta.module.scss';
import { Banner } from 'lib/types/item/banner';
export default function BannerCTA({
    banner,
    Ecategory,
    Eaction,
    Elabel1,
    Elabel2,
    gender,
    bannerText,
    currentLang
}: {
    banner: Banner;
    Ecategory: string;
    Eaction: string;
    Elabel1: string;
    Elabel2: string;
    gender: string;
    bannerText: string;
    currentLang: string;
}): JSX.Element {
    return (
        <div className={styles.midSectionContent}>
            <div>
                <p>
                    {bannerText ? bannerText : banner.text}
                    {bannerText ? null : (
                        <a
                            href={`https://rabbits.webcam/?id=2333&product_id=36&tag=signup&passthru1=${gender}&passthru2=${currentLang}&exid={exid}&tfid={tfid}`}
                            data-event-category={Ecategory}
                            data-event-action={Eaction}
                            data-event-label={Elabel1}
                            className={styles.bannerLink}>
                            {banner.cta_text}
                        </a>
                    )}
                </p>
            </div>
            <a
                className={buttonStyles.primaryButton}
                href={`https://rabbits.webcam/?id=2333&product_id=36&tag=signup&passthru1=${gender}&passthru2=${currentLang}&exid={exid}&tfid={tfid}`}
                data-event-category={Ecategory}
                data-event-action={Eaction}
                data-event-label={Elabel2}>
                <span>{banner.btn_text.toUpperCase()}</span>
            </a>
        </div>
    );
}
