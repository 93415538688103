import styles from '@styles/scss/performer/_card.module.scss';
import { PerformerCard } from 'lib/types/item/performerCard';
import Image from 'next/future/image';
import { useEffect, useState } from 'react';

export default function Card({
    performerCard,
    currentLang,
    gender,
    randomNumber,
    index,
    currentlyHovered
}: {
    performerCard: PerformerCard;
    currentLang: string;
    gender: string;
    randomNumber: number;
    index: number;
    currentlyHovered: boolean;
}): JSX.Element {
    let url: string;
    const [isHovered, setIsHovered] = useState(false);
    const [isPlaying, setIsPlaying] = useState(false);

    useEffect(() => {
        setIsPlaying(false);
        if (currentlyHovered === true) {
            setIsPlaying(false);
        } else if (randomNumber === index) {
            setIsPlaying(true);
        }
    }, [randomNumber, currentlyHovered]);

    const handleMouseEnter = () => {
        setIsHovered(true);
    };

    const handleMouseLeave = () => {
        setIsHovered(false);
    };

    if (currentLang === 'en') {
        url = '/live/' + performerCard.username + '/';
    } else {
        url = '/' + currentLang + `/live/` + performerCard.username + '/';
    }

    let categoryName;
    let superCategoryName;

    if (gender === 'boys') {
        categoryName = 'boy';
        superCategoryName = 'boys';
    } else if (gender === 'tranny') {
        categoryName = 'transgender';
        superCategoryName = 'tranny';
    } else {
        categoryName = 'girls';
        superCategoryName = 'girls';
    }

    const thumbSrc = `${performerCard.card_image?.replace('jpg', 'webp')}`;
    const videoSrc = `${performerCard.live_pre_roll_url}`;

    return (
        <div className={styles.user}>
                <a
                    href={`https://rabbits.webcam/?id=2333&product_id=36&passthru1=${performerCard.username}&passthru2=${currentLang}&passthru3=${categoryName}&passthru4=${superCategoryName}&producturl_id=19421&exid={exid}&tfid={tfid}`}>
                    {(isHovered || isPlaying) &&
                    performerCard.is_online === 1 &&
                    videoSrc !== 'null' ? (
                        <div className={styles.performerVideo}>
                            <video
                                src={videoSrc}
                                autoPlay
                                muted
                                loop
                                onMouseLeave={handleMouseLeave}
                            />
                        </div>
                    ) : (
                        <div className={styles.performerImage}>
                            <Image
                                loader={() => thumbSrc}
                                unoptimized={true}
                                src={thumbSrc}
                                alt={`Chat with ${performerCard.username}`}
                                height={345}
                                width={460}
                                onMouseEnter={handleMouseEnter}
                                className={styles.zoom}
                                priority={true}
                            />
                        </div>
                    )}
                </a>
                <a href={url}>
                    <div className={styles.userInfo}>
                        <div className={styles.userName}>
                            <svg
                                width="8"
                                height="8"
                                viewBox="0 0 8 8"
                                fill="none"
                                xmlns="https://www.w3.org/2000/svg">
                                <circle
                                    cx="4"
                                    cy="4"
                                    r="4"
                                    fill={performerCard.is_online === 1 ? '#82C97C' : '#C97C7C'}
                                />
                            </svg>
                            {performerCard.username} ({performerCard.age})
                        </div>
                        <div id="performerRating" className={styles.userRating}>
                            {performerCard.rating}%
                            <div className={styles.heartContainer}>
                                <svg
                                    width="15"
                                    height="15"
                                    viewBox="0 0 20 19"
                                    fill="none"
                                    xmlns="https://www.w3.org/2000/svg">
                                    <path
                                        d="M10 18.35L8.55 17.03C3.4 12.36 0 9.28 0 5.5C0 2.42 2.42 0 5.5 0C7.24 0 8.91 0.81 10 2.09C11.09 0.81 12.76 0 14.5 0C17.58 0 20 2.42 20 5.5C20 9.28 16.6 12.36 11.45 17.04L10 18.35Z"
                                        fill="#F8F8F8"
                                    />
                                </svg>
                            </div>
                        </div>
                        <div className={`${styles.videoTest} ${styles.hidden}`}>
                            {performerCard.total_videos > 0 ? (
                                <div id="video" className={styles.videoIcon}>
                                    <svg
                                        width="40"
                                        height="40"
                                        viewBox="0 0 40 40"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg">
                                        <rect
                                            x="0.4"
                                            y="0.4"
                                            width="39.2"
                                            height="39.2"
                                            rx="19.6"
                                            fill="#222222"
                                        />
                                        <path
                                            d="M12 14H10V28C10 29.1 10.9 30 12 30H26V28H12V14ZM28 10H16C14.9 10 14 10.9 14 12V24C14 25.1 14.9 26 16 26H28C29.1 26 30 25.1 30 24V12C30 10.9 29.1 10 28 10ZM20 22.5V13.5L26 18L20 22.5Z"
                                            fill="#F8F8F8"
                                        />
                                        <rect
                                            x="0.4"
                                            y="0.4"
                                            width="39.2"
                                            height="39.2"
                                            rx="19.6"
                                            stroke="#C9BA7C"
                                            strokeWidth="0.8"
                                        />
                                    </svg>
                                </div>
                            ) : (
                                <div id="videoRating" className={styles.userRatingVideo}>
                                    {performerCard.rating}%
                                    <div className={styles.heartContainer}>
                                        <svg
                                            width="15"
                                            height="15"
                                            viewBox="0 0 20 19"
                                            fill="none"
                                            xmlns="https://www.w3.org/2000/svg">
                                            <path
                                                d="M10 18.35L8.55 17.03C3.4 12.36 0 9.28 0 5.5C0 2.42 2.42 0 5.5 0C7.24 0 8.91 0.81 10 2.09C11.09 0.81 12.76 0 14.5 0C17.58 0 20 2.42 20 5.5C20 9.28 16.6 12.36 11.45 17.04L10 18.35Z"
                                                fill="#F8F8F8"
                                            />
                                        </svg>
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                </a>
            </div>
    );
}
