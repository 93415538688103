import styles from '@styles/scss/common/_genderButtons.module.scss';
import { GenderButton } from 'lib/types/item/genderButtons';
import { useRouter } from 'next/dist/client/router';
import Link from 'next/link';
import { useEffect } from 'react';

export default function GenderButtons({
    genderButtons,
    currentLang
}: {
    genderButtons: GenderButton;
    currentLang: string;
}): JSX.Element {
    const router = useRouter();

    let girlStyle = '';
    let guyStyle = '';
    let transStyle = '';
    if (router.asPath.includes('guys')) {
        guyStyle = styles.categoryActive;
    } else if (router.asPath.includes('trans')) {
        transStyle = styles.categoryActive;
    } else {
        girlStyle = styles.categoryActive;
    }

    useEffect(() => {
        window.onscroll = function () {
            const currentScrollPos = window.pageYOffset;
            const genderButtons: any = document.getElementById('genderButtons');
            if (genderButtons) {
                if (currentScrollPos >= 300) {
                    genderButtons.style.top = '-55px';
                } else {
                    genderButtons.style.top = '53px';
                }
            }
        };
    }, []);
    let fontSize;
    if (currentLang === 'ru') {
        fontSize = styles.russianFont;
    } else {
        fontSize = null;
    }

    return (
        <main id="genderButtons" className={`${styles.buttonsContainer} ${fontSize}`}>
            <Link
                href={currentLang === 'en' ? '/' : '/' + currentLang + '/'}
                data-event-category="User Action"
                data-event-action="Header Mobile - Girls Button"
                data-event-label="Header - Girls Button">
                <span className={`${girlStyle} ${styles.firstSpan}`}>
                    {genderButtons.girls.toUpperCase()}
                </span>
            </Link>
            <Link
                href={currentLang === 'en' ? '/guys/' : '/' + currentLang + '/' + 'guys'}
                data-event-category="User Action"
                data-event-action="Header Mobile - Guys Button"
                data-event-label="Header - Guys Button">
                <span className={guyStyle}>{genderButtons.guys.toUpperCase()}</span>
            </Link>
            <Link
                href={currentLang === 'en' ? '/trans/' : '/' + currentLang + '/' + 'trans'}
                data-event-category="User Action"
                data-event-action="Header Mobile - Trans Button"
                data-event-label="Header - Trans Button">
                <span className={transStyle}>{genderButtons.trans.toUpperCase()}</span>
            </Link>
        </main>
    );
}
