import styles from '@styles/scss/common/_pagination.module.scss';
import { PageMeta } from 'lib/types/item/pageMeta';
import Link from 'next/link';
import { useRouter } from 'next/router';

export default function Pagination({ pageMeta }: { pageMeta: PageMeta }): JSX.Element {
    const currentPage = pageMeta.page;
    const totalPages = pageMeta.totalPages;

    let category;
    const router = useRouter();

    const route: any = router.asPath.split('/')[1];

    if (!isNaN(route)) {
        category = '';
    } else {
        category = route + '/';
    }

    const calculatePagination = (currentPage: number, position: number) => {
        switch (currentPage) {
            case 1:
                switch (position) {
                    case 2:
                        return currentPage + 1;
                    case 3:
                        return currentPage + 2;
                    default:
                        return currentPage + 3;
                }
            case 2:
                switch (position) {
                    case 2:
                        return currentPage;
                    case 3:
                        return currentPage + 1;
                    default:
                        return currentPage + 2;
                }
            case totalPages:
                switch (position) {
                    case 2:
                        return currentPage - 2;
                    case 3:
                        return currentPage - 1;
                    default:
                        return currentPage;
                }
            default:
                switch (position) {
                    case 2:
                        return currentPage - 1;
                    case 3:
                        return currentPage;
                    default:
                        return currentPage + 1;
                }
        }
    };

    return (
        <div className={styles.paginationContainer}>
            {currentPage - 1 < 1 ? null : (
                <>
                    <Link
                        href={`${
                            currentPage - 10 < 1
                                ? `/${category}`
                                : '/' + category + (currentPage - 10)
                        }`}>
                        <a className={styles.paginationItem}>
                            <svg
                                width="7"
                                height="12"
                                viewBox="0 0 7 12"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg">
                                <path
                                    d="M6 1L1 6L6 11"
                                    stroke="#222222"
                                    strokeMiterlimit="10"
                                    strokeLinecap="square"
                                    strokeLinejoin="round"
                                />
                            </svg>
                            <svg
                                width="7"
                                height="12"
                                viewBox="0 0 7 12"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg">
                                <path
                                    d="M6 1L1 6L6 11"
                                    stroke="#222222"
                                    strokeMiterlimit="10"
                                    strokeLinecap="square"
                                    strokeLinejoin="round"
                                />
                            </svg>
                        </a>
                    </Link>
                    <Link
                        href={`${
                            currentPage - 1 < 2
                                ? `/${category}`
                                : '/' + category + (currentPage - 1)
                        }`}>
                        <a className={styles.paginationItem}>
                            <svg
                                width="7"
                                height="12"
                                viewBox="0 0 7 12"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg">
                                <path
                                    d="M6 1L1 6L6 11"
                                    stroke="#222222"
                                    strokeMiterlimit="10"
                                    strokeLinecap="square"
                                    strokeLinejoin="round"
                                />
                            </svg>
                        </a>
                    </Link>
                </>
            )}
            <Link href={`/${category}`}>
                <a
                    className={`${styles.paginationItem} ${
                        currentPage === 1 ? styles.activePage : ''
                    }`}>
                    <span>1</span>
                </a>
            </Link>
            {currentPage > 2 ? <div className={styles.paginationEllipsis}>...</div> : null}
            {totalPages === 3 && currentPage === 3 ? 
            null

                    :
                    <Link href={`/${category}${calculatePagination(currentPage, 2)}`}>
                    <a
                        className={`${
                            currentPage >= 999 ? styles.paginationLarge : styles.paginationItem
                        } ${currentPage === 2 ? styles.activePage : ''}`}>
                        {calculatePagination(currentPage, 2)}
                    </a>
                </Link>
            }
            <Link href={`/${category}${calculatePagination(currentPage, 3)}`}>
                <a
                    className={`${
                        currentPage >= 999 ? styles.paginationLarge : styles.paginationItem
                    } ${currentPage >= 3 && currentPage != totalPages ? styles.activePage : ''}`}>
                    {calculatePagination(currentPage, 3)}
                </a>
            </Link>
            {totalPages === 3 && currentPage != 3 ? null :
                        <Link href={`/${category}${calculatePagination(currentPage, 4)}`}>
                        <a
                            className={`${
                                currentPage >= 999 ? styles.paginationLarge : styles.paginationItem
                            } ${currentPage === totalPages ? styles.activePage : ''}`}>
                            {calculatePagination(currentPage, 4)}
                        </a>
                    </Link>
            }
            {currentPage + 1 > totalPages ? null : (
                <>
                    <Link
                        href={`${
                            currentPage + 1 > totalPages
                                ? '/' + category + totalPages
                                : '/' + category + (currentPage + 1)
                        }`}>
                        <a className={styles.paginationItem}>
                            <svg
                                width="7"
                                height="12"
                                viewBox="0 0 7 12"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg">
                                <path
                                    d="M1 1L6 6L1 11"
                                    stroke="#222222"
                                    strokeMiterlimit="10"
                                    strokeLinecap="square"
                                    strokeLinejoin="round"
                                />
                            </svg>
                        </a>
                    </Link>
                    <Link
                        href={`${
                            currentPage + 10 > totalPages
                                ? '/' + category + totalPages
                                : '/' + category + (currentPage + 10)
                        }`}>
                        <a className={styles.paginationItem}>
                            <svg
                                width="7"
                                height="12"
                                viewBox="0 0 7 12"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg">
                                <path
                                    d="M1 1L6 6L1 11"
                                    stroke="#222222"
                                    strokeMiterlimit="10"
                                    strokeLinecap="square"
                                    strokeLinejoin="round"
                                />
                            </svg>
                            <svg
                                width="7"
                                height="12"
                                viewBox="0 0 7 12"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg">
                                <path
                                    d="M1 1L6 6L1 11"
                                    stroke="#222222"
                                    strokeMiterlimit="10"
                                    strokeLinecap="square"
                                    strokeLinejoin="round"
                                />
                            </svg>
                        </a>
                    </Link>
                </>
            )}
        </div>
    );
}
